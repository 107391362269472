import React, { Fragment, useEffect, useState } from 'react';
import { getTravelDetails } from "../../services/travel";
import _ from 'lodash';
import './home.scss';

const Home = () => {

    const [ travelData, setTravelData ] = useState({});

    useEffect(() => {

        const getTravelData = async () => {
            const data = await getTravelDetails(process.env.REACT_APP_CONTENTFUL_TRAVEL_ID);
            if (!_.isEmpty(data)) {
                setTravelData(data);
            }
        }
        getTravelData();
    }, []);

    return (
        <Fragment>
            <div className="App">
                <header className="App-header">
                    { !_.isEmpty(travelData) && (
                        <Fragment>
                            <img src={travelData.fields.place[0].image} className="travel-home-image" alt="" />
                        </Fragment>
                    )}
                    <h2 className="home-header">Cruising Through Clouds</h2>
                    <h3 className="construction-message">This page is under construction. Visit us back soon to explore about our travel and lifestyle!</h3>
                </header>
            </div>
        </Fragment>
    );
}

export default Home;