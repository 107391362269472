import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getTravelDetails } from "../../services/travel";
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faPinterest, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';
import './navbar.scss';

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [ navbarData, setNavbarData ] = useState({});

    useEffect(() => {

        const getNavbarData = async () => {
            const data = await getTravelDetails(process.env.REACT_APP_CONTENTFUL_NAVBAR_ID);
            if (!_.isEmpty(data)) {
                console.log(data);
                setNavbarData(data);
            }
        }
        getNavbarData();
    }, []);

    const handleNavBar = () => {
        setIsOpen(true)
        if (isMobile) {
            document.body.style.overflow = 'hidden';
        }
    }

    const closeNavBar = () => {
        setIsOpen(false)
        if (isMobile) {
            document.body.style.overflow = 'unset';
        }
    }

    return (
        <Fragment>
        { !_.isEmpty(navbarData) && (
            <div className={`${isOpen && !isMobile ? 'side-bar side-bar-open' : 'side-bar'}`}>
                <img src={navbarData.fields.navbarContents.image} alt="" className="side-bar-image" />
            </div>
        )}
        <div className={`navbar-wrapper ${isOpen ? 'expand-navbar' : ''}`}>
            <div className="navbar">
                <div className="mobile-header-wrapper">
                    <button className={`menu ${!isOpen ? '' : 'hidden'}`} onClick={() => handleNavBar()}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                    <button className={`menu-close ${isOpen ? '' : 'hidden'}`} onClick={() => closeNavBar()}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <div className="mobile-header">Cruising through clouds</div>
                </div>
                <ol className={`bar-options ${!isOpen ? 'hide-mobile' : 'show-mobile'}`}>
                    <li><Link to="/">HOME</Link></li>
                    <li><Link to="/travel">TRAVEL</Link></li>
                    <li><Link to="/food">FOOD</Link></li>
                    <li><Link to="/about">ABOUT US</Link></li>
                    <li><Link to="/contact">CONTACT</Link></li>
                </ol>
                <ol className={`social-links mobile-links ${!isOpen ? 'hide-mobile' : 'show-mobile'}`}>
                    <li><a href="https://www.instagram.com/cruisingthroughclouds/"><FontAwesomeIcon icon={faInstagram} /></a></li>
                    <li><a href="https://www.pinterest.ca/cruisingthroughclouds/"><FontAwesomeIcon icon={faPinterest} /></a></li>
                    <li><a href="https://www.tiktok.com/@cruisingthroughclouds"><FontAwesomeIcon icon={faTiktok} /></a></li>
                </ol>
            </div>
        </div>
        </Fragment>
    );
}

export default NavBar;