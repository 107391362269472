import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import './App.scss';
import Home from './pages/home';
import NavBar from './common/NavBar';

const App = () => {
    return (
        <Router>
            <div>
                <NavBar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<Home />} />
                    <Route path="/travel" element={<Home />} />
                    <Route path="/food" element={<Home />} />
                    <Route path="/contact" element={<Home />} />
                    <Route path="/about" element={<Home />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
